import {
  FieldArray,
  Form, Formik,
} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { updateUser } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  settingsContainer: {
    '& > div': {
      backgroundColor: '#F5F5F5',
      borderRadius: '12px',
      padding: '20px',
    },
    '& > div:first-child': {
      margin: '20px 0 0',
    },
    '& > div:not(:first-child)': {
      margin: '40px 0 0',
    },
  },
  name: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.grayScale9.main,
  },
  description: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.lightGray.main,
    margin: '8px 0 20px',
  },
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'row',
    '& > div:not(:last-child)': {
      marginRight: '40px',
    },
  },
  checkbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.grayScale9.main,

    '& > span': {
      marginRight: '10px',
    },
  },
  select: {
    margin: '30px 0 0',
    maxWidth: '200px',
  },
}));

const NotificationSettingsForm = ({ settings }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { userInfo } = useUserInfoContext();

  if (!userInfo) {
    return (<LoadingIndicator />);
  }

  return (
    <div>
      <Formik
        initialValues={{
          notificationSettings: userInfo.notificationSettings,
        }}
        onSubmit={async (values, { setStatus }) => {
          const { notificationSettings } = values;

          try {
            await updateUser({ notificationSettings });

            setCurrentAlert('success', 'Your selection has been saved');
          } catch (err) {
            setStatus(ESaveButtonFormikStatus.Error);
            console.error('error', err);
            showAPIErrorAlert(setCurrentAlert, err);
          }
        }}
        validationSchema={Yup.object().shape({
          notificationSettings: Yup.array().of(Yup.object().shape({
            setting: Yup.string().required(),
            selectedTypeOptions: Yup.array().of(Yup.string()).required(),
            selectedIntervalOption: Yup.string(),
          })),
        })}
      >
        {({ values, handleSubmit }) => (
          <Form>
            <div className={classes.settingsContainer}>
              <FieldArray
                name="notificationSettings"
                render={(arrayHelpers) => values.notificationSettings.map(({
                  setting, selectedTypeOptions, selectedIntervalOption,
                }, index) => {
                  const notificationSetting = settings.filter(({ _id }) => _id === setting)[0];

                  const {
                    name, description, typeOptions, intervalOptions, modifiable,
                  } = notificationSetting;

                  const toggleTypeOption = (option) => {
                    const newSelectedTypeOptions = [...selectedTypeOptions];
                    if (newSelectedTypeOptions.includes(option)) {
                      newSelectedTypeOptions.splice(newSelectedTypeOptions.indexOf(option), 1);
                    } else {
                      newSelectedTypeOptions.push(option);
                    }

                    arrayHelpers.replace(index, {
                      setting,
                      selectedTypeOptions: newSelectedTypeOptions,
                      selectedIntervalOption,
                    });

                    handleSubmit(); // Trigger form submission to auto-save
                  };

                  const selectIntervalOption = (interval) => {
                    arrayHelpers.replace(index, {
                      setting,
                      selectedTypeOptions,
                      selectedIntervalOption: interval,
                    });

                    handleSubmit(); // Trigger form submission to auto-save
                  };

                  return (
                    <div key={index}>
                      <div className={classes.name}>{name}</div>
                      <p className={classes.description}>{description}</p>

                      <div className={classes.checkboxesContainer}>
                        {typeOptions.map((option) => (
                          <div key={option} className={classes.checkbox}>
                            <Checkbox
                              color="primary"
                              checked={selectedTypeOptions.includes(option)}
                              tabIndex={-1}
                              onClick={() => toggleTypeOption(option)}
                              disabled={!modifiable}
                            />
                            {option}
                          </div>
                        ))}
                      </div>

                      {/* Only show the interval options if one of the type options are selected */}
                      {selectedTypeOptions.length > 0 && intervalOptions && intervalOptions.length > 0 && (
                        <div className={classes.select}>
                          <CustomSelect
                            labelText="Interval"
                            labelBold
                            labelLight
                            items={intervalOptions.map((interval) => ({ value: interval, name: interval }))}
                            inputProps={{
                              value: selectedIntervalOption,
                              onChange: (e) => selectIntervalOption(e.target.value),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NotificationSettingsForm;
