import { container } from 'assets/jss/material-dashboard-pro-react';
import React from 'react';
import { EAgentLandingPageType } from 'types/agent-landing-page';

import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import AgentLandingHeader from 'components/AgentLanding/AgentLandingHeader/AgentLandingHeader';
import Contact from 'components/AgentLanding/Contact/Contact';
import DigitalAd from 'components/AgentLanding/DigitalAd/DigitalAd';
import HomeEstimation from 'components/AgentLanding/HomeEstimation/HomeEstimation';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    background: theme.palette.grayScale7.main,
  },
  container: {
    ...container,
    position: 'relative',
  },
  section: {
    margin: '-235px 0 113px',
  },
  card: {
    position: 'relative',
    padding: '70px 80px !important',
    background: theme.palette.white.main,
    filter: 'drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25))',
    borderRadius: '8px',

    [theme.breakpoints.down('sm')]: {
      padding: '35px 40px !important',
    },
  },
}));

const HomePage = ({
  agentLandingPageType,
  agentLandingPageToken,
  isConsentGiven,
  setIsConsentGiven,
  setIsDownloadingReport,
  agentData,
  propertyData,
  similarHomes,
  eventId,
}) => {
  const classes = useStyles();

  let content;

  if (agentLandingPageType === EAgentLandingPageType.ComparableProperties) {
    content = (
      <HomeEstimation
        agentLandingPageToken={agentLandingPageToken}
        isConsentGiven={isConsentGiven}
        setIsConsentGiven={setIsConsentGiven}
        setIsDownloadingReport={setIsDownloadingReport}
        agentData={agentData}
        propertyData={propertyData}
        similarHomes={similarHomes}
        eventId={eventId} // For agent's previews
      />
    );
  } else if (agentLandingPageType === EAgentLandingPageType.ContactForm) {
    content = (
      <div className={classes.section}>
        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.card}>
            <Contact
              agentLandingPageToken={agentLandingPageToken}
              agentData={agentData}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  } else if (agentLandingPageType === EAgentLandingPageType.DigitalAd) {
    content = (
      <div className={classes.section}>
        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.card}>
            <DigitalAd
              agentLandingPageToken={agentLandingPageToken}
              agentData={agentData}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AgentLandingHeader agentLandingPageType={agentLandingPageType} agentLandingPageToken={agentLandingPageToken} agentData={agentData} />

      {content}
    </div>
  );
};

export default HomePage;
