import logo from 'assets/img/logo-dark.png';
import styles from 'assets/jss/material-dashboard-pro-react/components/agentLandingFooterStyle';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

const useStyles = makeStyles(styles);

const AgentLandingFooter = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <GridContainer alignItems="center">
          <GridItem xs={12} md={3}>
            <a href="/">
              <img src={logo} alt="Harvist" className={classes.logo} />
            </a>
          </GridItem>
          <GridItem xs={12} md={9}>
            <div className={classes.copyrightText}>
              &copy;
              {' '}
              {1900 + new Date().getYear()}
              . "All rights reserved" is a copyright formality indicating that the copyright holder reserves, or holds for its own use, all the rights provided by copyright.
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </footer>
  );
};

export default AgentLandingFooter;
