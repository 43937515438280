import { Field, Form, Formik } from 'formik';
import React from 'react';
import { EAgentLandingPageType } from 'types/agent-landing-page';
import * as Yup from 'yup';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import DashboardBubble from 'components/DashboardBubble/DashboardBubble';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { updateUser } from 'utils/api';
import { getAgentLandingPageSampleUrlFromType, showAPIErrorAlert } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  infoBubble: {
    margin: '0 auto 47px',

    '& a': {
      font: 'inherit',
      verticalAlign: 'top', // Line up with text
      textDecoration: 'underline',
      color: theme.palette.orange.main,

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  saveButtonContainer: {
    float: 'right',
    margin: '26px 0 0',
  },
}));

const MarketingMaterialsForm = (props, ref) => {
  const theme = useTheme();
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { userInfo, reloadUserInfo } = useUserInfoContext();

  return (
    <Formik
      innerRef={ref}
      initialValues={{
        agentLandingBio: userInfo.agentInfo.agentLandingBio || '',
        disclaimerStatement: userInfo.agentInfo.disclaimerStatement || '',
      }}
      onSubmit={async (values, { resetForm, setStatus }) => {
        const { agentLandingBio, disclaimerStatement } = values;

        try {
          await updateUser({
            agentInfo: {
              agentLandingBio,
              disclaimerStatement,
            },
          });

          // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
          // pass in the current values here to use that as the new initial state when resetting.
          resetForm({ values });
        } catch (err) {
          setStatus(ESaveButtonFormikStatus.Error);
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        agentLandingBio: Yup.string().min(1).max(2500),
        disclaimerStatement: Yup.string().min(1).max(500),
      })}
    >
      {(props2) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values, validateForm,
        } = props2;

        // Validate to show errors for new users
        React.useEffect(() => { validateForm(); }, []);

        return (
          <Form>
            <FormikOnError>
              <GridContainer justifyContent="center">
                <GridItem xs={12} lg={10} xl={7}>
                  <GridContainer justifyContent="center">

                    <GridItem xs={12}>
                      <DashboardBubble
                        rootClass={classes.infoBubble}
                        backgroundColor={theme.palette.primary.light}
                        icon={(<CheckCircleIcon htmlColor={theme.palette.secondary.main} />)}
                        content={(
                          <>
                            {'We print a QR code on letters and postcards that links to a landing page with your profile on it. There are two types of landing pages: '}
                            <a href={getAgentLandingPageSampleUrlFromType(EAgentLandingPageType.ComparableProperties)} target="_blank" rel="noreferrer">one with comparable properties</a>
                            {' and '}
                            <a href={getAgentLandingPageSampleUrlFromType(EAgentLandingPageType.ContactForm)} target="_blank" rel="noreferrer">one with only a contact form</a>
                            .
                          </>
                        )}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <Field
                        name="agentLandingBio"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Agent Landing Page Custom Biography"
                            inputProps={{
                              ...field,
                              multiline: true,
                              rows: 12,
                            }}
                            helperText={(
                              <span>
                                {`${values[field.name]?.length ?? 0} / 2500 characters`}
                                <br />
                                <br />
                                This will be used on the your landing page's biography page when a lead scans the QR code on your letters or postcards. The default biography will be used if this field is left blank.
                              </span>
                            )}
                            error={errors[field.name] !== undefined}
                          />
                        )}
                      </Field>
                    </GridItem>

                    <GridItem xs={12} style={{ marginTop: '32px' }}>
                      <Field
                        name="disclaimerStatement"
                      >
                        {({ field }) => (
                          <CustomInputDashboard
                            labelText="Custom Postcard Disclaimer Statement"
                            inputProps={{
                              ...field,
                              multiline: true,
                              rows: 8,
                              placeholder: 'This is not meant as a solicitation if your property is currently listed with another broker.',
                              endAdornment: <InfoOutlinedIcon />,
                            }}
                            helperText={`${values[field.name]?.length ?? 0} / 500 characters`}
                            error={errors[field.name] !== undefined}
                            tooltip="We recommend previewing your postcards after to make sure your custom disclaimer displays correctly!"
                          />
                        )}
                      </Field>
                    </GridItem>

                    <GridItem xs={12}>
                      <div className={classes.saveButtonContainer}>
                        <SaveButton onSave={reloadUserInfo} />
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </FormikOnError>
          </Form>
        );
      }}
    </Formik>
  );
};

export default React.forwardRef(MarketingMaterialsForm);
