import styles from 'assets/jss/material-dashboard-pro-react/components/agentLandingHeaderStyle';
import cx from 'classnames';
import React from 'react';
import { EAgentLandingPageType } from 'types/agent-landing-page';

import { Hidden, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { urlWithoutScheme } from 'utils/lib';

import ContactModal from '../Contact/ContactModal';

const useStyles = makeStyles(styles);

const AgentLandingHeader = ({ agentLandingPageType, agentLandingPageToken, agentData }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));

  const [emailModalOpen, setEmailModalOpen] = React.useState(false);

  return (
    <div className={classes.hero}>
      <div className={classes.heroOverlay} />

      {/* {emailModalOpen && (<EmailAgentForm onClose={() => setEmailModalOpen(false)} agentLandingPageToken={agentLandingPageToken} />)} */}
      {emailModalOpen && (<ContactModal onClose={() => setEmailModalOpen(false)} agentLandingPageToken={agentLandingPageToken} agentData={agentData} />)}

      <GridContainer className={cx(classes.container, classes.heroContainer)} alignItems="center">
        <GridItem xs={12}>
          <div className={classes.main}>
            <div className={classes.left}>
              {/* NOTE: Optional agent picture here */}
              {agentData.agentPictureUrl && (<img src={agentData.agentPictureUrl} alt="" />)}
              <div>
                <h1>{`${agentData.firstName} ${agentData.lastName}`}</h1>

                <Hidden smDown>
                  <p>
                    {`License# ${agentData.agentDreNumber}`}
                    {'      |      '}
                    <a href={`tel:${agentData.phoneNumber}`}>{agentData.phoneNumber}</a>
                    {agentData.website && (
                      <>
                        <br />
                        <a href={agentData.website}>{urlWithoutScheme(agentData.website)}</a>
                      </>
                    )}
                  </p>
                </Hidden>

                <Hidden mdUp>
                  <p>{`License# ${agentData.agentDreNumber}`}</p>
                  <br />
                  <a href={`tel:${agentData.phoneNumber}`}>{agentData.phoneNumber}</a>
                  {agentData.website && (
                    <>
                      <br />
                      <a href={agentData.website}>{urlWithoutScheme(agentData.website)}</a>
                    </>
                  )}
                </Hidden>
              </div>
            </div>
            {agentLandingPageType === EAgentLandingPageType.ComparableProperties && (
              <div className={classes.right}>
                Got Questions?
                <br />
                <Button
                  color="primary"
                  outlined
                  round
                  size={!isMobileWidth ? 'lg' : null}
                  onClick={() => setEmailModalOpen(true)}
                >
                  {`Email ${agentData.firstName}`}
                </Button>
              </div>
            )}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AgentLandingHeader;
