import React from 'react';

import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  badge: {
    '& > span': {
      right: -6.5,
      top: -10,
    },
  },
}));

interface Props {
  main: string;
  alternates?: string[];
}

const BadgedContacts: React.FC<Props> = ({ main, alternates = [] }: Props) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={<div style={{ whiteSpace: 'pre-line' }}>{alternates.join('\n')}</div>}
      placement="top"
      open={!alternates.length ? false : undefined}
    >
      <Badge
        color="secondary"
        badgeContent={alternates.length + 1}
        className={classes.badge}
        invisible={!alternates.length}
      >
        {main}
      </Badge>
    </Tooltip>
  );
};

export default BadgedContacts;
