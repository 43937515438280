import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  header: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  row: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
}));

interface Props {
  fullName: string;
  email: string;
  phoneNumber: string;
  message?: string;
  onClose: () => void;
}

const ViewFormSubmissionModal: React.FC<Props> = ({
  fullName, email, phoneNumber, message, onClose,
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      open
      scroll="body"
      keepMounted
      maxWidth="md"
      onClose={onClose}
    >
      <SimpleModalHeader onClose={onClose} title="Contact Form Submission" />

      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} sm={8} md={6}>
            <Typography variant="h5" className={classes.header}>
              {fullName}
            </Typography>
            <Typography variant="body1" className={classes.row}>
              <span className={classes.label}>Email: </span>
              {' '}
              {email}
            </Typography>
            <Typography variant="body1" className={classes.row}>
              <span className={classes.label}>Phone Number: </span>
              {' '}
              {phoneNumber}
            </Typography>
            {message && (
              <Typography variant="body1" className={classes.row}>
                <span className={classes.label}>Message: </span>
                {' '}
                {message}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default ViewFormSubmissionModal;
