import useSWR from 'swr';
import { IDeliveredTouchStat, IEmailDeliverabilityStats, ILeadResponseStats } from 'types/stats';

export const useCampaignDeliveredTouchStats = (campaignId: string) => {
  const { data, error } = useSWR<IDeliveredTouchStat[]>(`/campaigns/${campaignId}/stats/delivered-touches`);

  return {
    deliveredTouchStats: data,
    isLoadingDeliveredTouchStats: !data,
    isLoadingDeliveredTouchStatsError: error,
  };
};

export const useCampaignLeadResponseStats = (campaignId: string) => {
  const { data, error } = useSWR<ILeadResponseStats>(`/campaigns/${campaignId}/stats/lead-responses`);

  return {
    leadResponseStats: data,
    isLoadingLeadResponseStats: !data,
    isLoadingLeadResponseStatsError: error,
  };
};

export const useCampaignEmailDeliverabilityStats = (campaignId: string) => {
  const { data, error } = useSWR<IEmailDeliverabilityStats>(`/campaigns/${campaignId}/stats/email-deliverability`);

  return {
    emailDeliverabilityStats: data,
    isLoadingEmailDeliverabilityStats: !data,
    isLoadingEmailDeliverabilityStatsError: error,
  };
};
