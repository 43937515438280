import React from 'react';

import PublicRoute from 'components/AuthDataProvider/PublicRoute';

import BiographyPage from 'views/AgentLanding/BiographyPage';
import HomePage from 'views/AgentLanding/HomePage';

// Routes for agent-specific websites/pages

// NOTE: Make sure to spread props here since we pass in props from the layout
const routes = [
  {
    exact: true,
    path: '/',
    layout: '',
    name: 'Home',
    render: (props) => (<PublicRoute component={HomePage} {...props} />),
  },
  {
    path: '/bio',
    layout: '',
    name: 'Biography',
    render: (props) => (<PublicRoute component={BiographyPage} {...props} />),
  },
];

export default routes;
