import styles from 'assets/jss/material-dashboard-pro-react/views/errorPageStyles';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

const useStyles = makeStyles(styles);

export default function AgentLandingErrorPage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <GridContainer className={classes.container}>
        <GridItem xs={12}>
          <h1 className={classes.title}>We're Sorry</h1>
          <h2 className={classes.subTitle}>We couldn't find this page!</h2>
        </GridItem>
      </GridContainer>
    </div>
  );
}
