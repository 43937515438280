export enum EAgentLandingPageType {
  ComparableProperties = 'COMPARABLE_PROPERTIES',
  ContactForm = 'CONTACT_FORM',
  DigitalAd = 'DIGITAL_AD',
}

export const agentLandingPageSampleTokens = {
  [EAgentLandingPageType.ComparableProperties]: 'sample-comparable-properties',
  [EAgentLandingPageType.ContactForm]: 'sample-contact-form',
  [EAgentLandingPageType.DigitalAd]: 'sample-digital-ad',
} as Record<EAgentLandingPageType, string>;
