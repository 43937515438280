import { useAdCampaignLeads } from 'data/ad-campaign';
import moment from 'moment';
import React from 'react';
import { ELeadResponseType } from 'types/lead-response';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Button from 'creative-components/CustomButtons/Button';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import {
  enumToReadableName, tableFilterPhoneNumbers,
} from 'utils/lib';

import ViewFormSubmissionModal from './ViewFormSubmissionModal';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
}));

const columns = [
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Name',
    accessor: 'fullName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
    filter: tableFilterPhoneNumbers,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
  },
];

interface Props {
  adCampaignId: string;
}

const leadResponseTypeToDescription = (type: ELeadResponseType): string => {
  if (type === ELeadResponseType.AgentLandingOpen) return 'Ad Click';
  if (type === ELeadResponseType.AgentLandingContactForm) return 'Contact Form Submission';

  return enumToReadableName(type);
};

export default ({ adCampaignId }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { leads } = useAdCampaignLeads(adCampaignId);

  const [data, setData] = React.useState<any[] | null>(null);
  const [openModal, setOpenModal] = React.useState<React.FC | null>(null);

  React.useEffect(() => {
    if (!leads) return;

    setData(leads.map((lead) => {
      const {
        type, date, fullName, phoneNumber, email, message,
      } = lead;

      return ({
        type: leadResponseTypeToDescription(type),
        date: moment(date).format('MMM D, YYYY, h:mm A'),

        fullName,
        phoneNumber,
        email,
        message,

        actions: (
          // Add a min height here for some padding
          <div style={{ minHeight: '41px' }}>
            {type === ELeadResponseType.AgentLandingContactForm && (
              <Tooltip
                title={(
                  <>
                    View Form Submission
                  </>
                )}
                placement="top"
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => setOpenModal(
                    () => (
                      <ViewFormSubmissionModal
                        fullName={fullName!}
                        email={email!}
                        phoneNumber={phoneNumber!}
                        message={message}
                        onClose={() => setOpenModal(null)}
                      />
                    ),
                  )}
                  color="dark"
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>
            )}
          </div>
        ),
      });
    }));
  }, [leads]);

  return (
    <>
      {openModal}

      <div className={classes.tableContainer}>
        {data ? (
          <ReactTable
            columns={columns}
            data={data}
          />
        ) : <LoadingIndicator />}
      </div>
    </>
  );
};
