import cx from 'classnames';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';
import CustomInput from 'creative-components/CustomInput/CustomInput';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import TermsCheckbox from 'components/AgentLanding/TermsCheckbox';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import ReCAPTCHAInput from 'components/ReCAPTCHAInput/ReCAPTCHAInput';

import { submitAgentLandingContactAgentForm } from 'utils/api';
import { isAgentLandingPageSample, nameRegExp, phoneRegExp } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  submittedMessage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: 'bold',
    color: theme.palette.orange.main,
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '28px',
    lineHeight: '32px',
    fontWeight: 'bold',
    color: theme.palette.black.main,
    marginBottom: '30px',
  },
  buttonContainer: {
    textAlign: 'center',

    '& > button': {
      margin: '32px 0 0',
      color: 'black',

      [theme.breakpoints.up('lg')]: {
        minWidth: '400px',
      },
    },
  },
  hidden: {
    visibility: 'hidden',
  },
}));

const Contact = ({
  agentLandingPageToken, agentData,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [submitted, setSubmitted] = React.useState(false);

  return (
    <Formik
      initialValues={{
        fullName: '',
        phoneNumber: '',
        email: '',
        message: '',
        recaptchaValue: '',
        agreesToTerms: true,
      }}
      onSubmit={async ({
        fullName, phoneNumber, email, message, recaptchaValue,
      }) => {
        showLoadingIndicatorModal();

        try {
          if (!isAgentLandingPageSample(agentLandingPageToken)) {
            await submitAgentLandingContactAgentForm(agentLandingPageToken, fullName, phoneNumber, email, message, recaptchaValue);
          }

          setSubmitted(true);
        } catch (error) {
          console.error('error', error);
        }

        hideLoadingIndicatorModal();
      }}
      validationSchema={Yup.object().shape({
        fullName: Yup.string().required().matches(nameRegExp, 'Name is not valid'),
        phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
        email: Yup.string().email().required(),
        message: Yup.string(),
        recaptchaValue: Yup.string().required(),
        agreesToTerms: Yup.boolean().isTrue().required(),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
        } = props;

        return (
          <Form>
            {submitted && (<span className={classes.submittedMessage}>Thank you for your message, I will be in touch!</span>)}

            <GridContainer className={cx(submitted && classes.hidden)}>
              <GridItem xs={12}>
                <h3 className={classes.title}>Get Your Personalized Property Valuation!</h3>
              </GridItem>
              <GridItem xs={12}>
                <Field name="fullName">
                  {({ field }) => (
                    <CustomInput
                      labelText="Full Name"
                      labelBold
                      labelRequired
                      inputProps={{
                        ...field,
                        autoComplete: 'name',
                        maxLength: 100,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12} lg={6}>
                <Field name="phoneNumber">
                  {({ field }) => (
                    <CustomInput
                      labelText="Phone Number"
                      labelBold
                      labelRequired
                      inputProps={{
                        ...field,
                        type: 'tel',
                        autoComplete: 'tel',
                      }}
                      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12} lg={6}>
                <Field name="email">
                  {({ field }) => (
                    <CustomInput
                      labelText="Email Address"
                      labelBold
                      labelRequired
                      inputProps={{
                        ...field,
                        autoComplete: 'email',
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field name="message">
                  {({ field }) => (
                    <CustomInput
                      labelText="Message"
                      labelBold
                      inputProps={{
                        ...field,
                        multiline: true,
                        rows: 6,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field name="recaptchaValue">
                  {({ field }) => (
                    <ReCAPTCHAInput
                      fieldName={field.name}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12} style={{ marginTop: '10px' }}>
                <Field name="agreesToTerms">
                  {({ field }) => (
                    <TermsCheckbox fieldName={field.name} />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12} className={classes.buttonContainer}>
                <Button
                  color="primary"
                  outlined
                  round
                  size="lg"
                  disabled={isSubmitting || !values.agreesToTerms}
                  onClick={handleSubmit}
                >
                  Let's Get Started!
                </Button>
              </GridItem>
            </GridContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Contact;
