import * as changeCase from 'change-case';
import { useCampaignLeadResponseStats } from 'data/campaign-stats';
import { saveAs } from 'file-saver';
import * as jsonToCsv from 'json-2-csv';
import moment from 'moment';
import React from 'react';
import { ELeadResponseType } from 'types/lead-response';
import { engagementLeadTypes, hotProspectLeadTypes, ILeadResponseData } from 'types/stats';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Button from 'creative-components/CustomButtons/Button';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import BadgedContacts from 'components/BadgedContacts';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import ViewEmailModal from 'components/ViewEmailModal';

import {
  enumToReadableName, formatPhoneNumber, getAgentLandingPageSampleUrlFromType, stringifyAddressObject, stringifyEventSiteAddressObject, tableFilterPhoneNumbers, transformObjectKeys,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
  exportButton: {
    float: 'right',
    marginTop: '20px',
  },
}));

const columns = [
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'APN',
    accessor: 'apn',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Phone Numbers',
    accessor: 'phoneNumbers',
    filter: tableFilterPhoneNumbers,
    Cell: ({ value }: { value: string[] }) => <BadgedContacts main={value[0]} alternates={value.length > 1 ? value.slice(1) : []} />,
  },
  {
    Header: 'Emails',
    accessor: 'emails',
    Cell: ({ value }: { value: string[] }) => <BadgedContacts main={value[0]} alternates={value.length > 1 ? value.slice(1) : []} />,
  },
  {
    Header: 'Owner Address',
    accessor: 'ownerAddress',
  },
  {
    Header: 'Site Address',
    accessor: 'siteAddress',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
  },
];

interface Props {
  campaign: any; // TODO: ADD TYPE FOR CAMPAIGNS
  hotProspectsOnly: boolean;
}

const leadResponseTypeToHumanString = (type: ELeadResponseType): string => {
  if (type === ELeadResponseType.AgentLandingOpen) return 'QR Code Scanned';

  return enumToReadableName(type);
};

export default ({ campaign, hotProspectsOnly }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { leadResponseStats } = useCampaignLeadResponseStats(campaign._id);

  const [data, setData] = React.useState<any[] | null>(null);
  const [openModal, setOpenModal] = React.useState<React.FC | null>(null);

  React.useEffect(() => {
    if (!leadResponseStats) return;

    const leadResponsesSortedByDate = (hotProspectsOnly ? hotProspectLeadTypes : engagementLeadTypes)
      .reduce((arr, leadResponseType) => [...arr, ...leadResponseStats[leadResponseType]], [] as ILeadResponseData[])
      .sort((a, b) => b.date.localeCompare(a.date));

    setData(leadResponsesSortedByDate.map((leadResponse) => {
      // The user always receives touches themselves for free, and aren't technically an "extra recipient", but
      // for simplicity's sake, they are here.
      const {
        type, date, isCampaignExtraRecipient, eventData, recipient, metadata,
      } = leadResponse;

      let name: string;
      let phoneNumber: string | undefined;
      let email: string | undefined;
      let ownerAddress: string;

      if (eventData?.eventDataOverride) {
        name = `${eventData.eventDataOverride.firstName || ''} ${eventData.eventDataOverride.lastName || ''}`; // || check here to prevent 'undefined' being concatenated
        phoneNumber = eventData.eventDataOverride.phoneNumber;
        email = eventData.eventDataOverride.email;
        ownerAddress = stringifyAddressObject(eventData.eventDataOverride.address);
      } else {
        name = `${recipient.firstName || ''} ${recipient.lastName || ''}`; // || check here to prevent 'undefined' being concatenated
        phoneNumber = formatPhoneNumber(recipient.phoneNumber!); // Format these since they aren't formatted already when imported
        email = recipient.email;
        ownerAddress = stringifyAddressObject(recipient.address);
      }

      const { alternateEmails } = recipient;
      const alternatePhoneNumbers = (recipient.alternatePhoneNumbers ?? []).map((s) => formatPhoneNumber(s));

      return ({
        type: leadResponseTypeToHumanString(type),
        date: moment(date).format('MMM D, YYYY, h:mm A'),
        apn: !isCampaignExtraRecipient ? eventData?.apn : 'N/A (Extra Recipient)',
        name,
        phoneNumbers: [phoneNumber, ...(alternatePhoneNumbers ?? [])],
        emails: [email, ...(alternateEmails ?? [])],

        // No address for the touches going to the agent directly, since a random campaign address is used and
        // there's no address object tied to the agent's (the recipient) contact object.
        ownerAddress: !isCampaignExtraRecipient ? ownerAddress : 'N/A (Extra Recipient)',

        siteAddress: !isCampaignExtraRecipient ? stringifyEventSiteAddressObject(eventData?.siteAddress) : 'N/A (Extra Recipient)',
        actions: (
          // Add a min height here for some padding
          <div style={{ minHeight: '41px' }}>
            {type === ELeadResponseType.EmailReply && metadata.relayData?.content?.html && (
              <Tooltip
                title={(
                  <>
                    View Email Reply
                  </>
                )}
                placement="top"
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => setOpenModal(
                    () => (
                      <ViewEmailModal
                        title="Email Reply"
                        emailSubject={metadata.relayData.content.subject}
                        emailHtml={metadata.relayData.content.html}
                        onClose={() => setOpenModal(null)}
                      />
                    ),
                  )}
                  color="dark"
                >
                  <VisibilityIcon />
                </Button>
              </Tooltip>
            )}

            {type !== ELeadResponseType.EmailClick && type !== ELeadResponseType.EmailReply && (
              <Tooltip
                title={(
                  <>
                    View QR Code
                    <br />
                    Landing Page
                  </>
              )}
                placement="top"
              >
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => window.open(getAgentLandingPageSampleUrlFromType(campaign.agentLandingPageType, eventData?._id), '_blank', 'noopener,noreferrer')}
                  color="dark"
                >
                  <OpenInNewIcon />
                </Button>
              </Tooltip>
            )}
          </div>
        ),
      });
    }));
  }, [leadResponseStats]);

  const handleExportToCsv = async () => {
    // Change column names to nicely capitalized text and replace undefined values with the empty string
    const csvData = await jsonToCsv.json2csv(data!.map((obj) => transformObjectKeys(obj, changeCase.capitalCase)).map((obj) => Object.entries(obj).reduce((newObj, [key, value]) => {
      // eslint-disable-next-line no-param-reassign
      newObj[key] = value === undefined ? '' : value;
      return newObj;
    }, {} as { [key: string]: any })));
    saveAs(new Blob([csvData], { type: 'text/csv;charset=utf-8' }), `campaign-${campaign._id}-${hotProspectsOnly ? 'hot-prospects' : 'engagements'}-export.csv`);
  };

  return (
    <>
      {openModal}

      <div className={classes.tableContainer}>
        {data ? (
          <ReactTable
            columns={columns}
            data={data}
          />
        ) : <LoadingIndicator />}
      </div>
      <div>
        <Button
          round
          color="primary"
          className={classes.exportButton}
          onClick={handleExportToCsv}
        >
          <GetAppIcon />
          Export
        </Button>
      </div>
    </>
  );
};
