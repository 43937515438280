import { modalCloseButton } from 'assets/jss/material-dashboard-pro-react';
import React from 'react';

import { ButtonBase, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Contact from './Contact';

const useStyles = makeStyles((theme) => ({
  modalCloseButton,
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    color: theme.palette.darkGray.main,
    margin: '0 0 32px',
  },
  button: {
    margin: '32px 0 0',
    color: 'black',
    width: '100%',
  },
}));

const ContactModal = ({ onClose, agentLandingPageToken, agentData }) => {
  const classes = useStyles();

  return (
    <Dialog
      open
      keepMounted
      maxWidth="lg"
      scroll="body"
      onClose={onClose}
    >
      <ButtonBase
        className={classes.modalCloseButton}
        aria-label="Close"
        onClick={onClose}
      >
        <CloseIcon />
      </ButtonBase>
      <Contact
        agentLandingPageToken={agentLandingPageToken}
        agentData={agentData}
      />
    </Dialog>
  );
};

export default ContactModal;
