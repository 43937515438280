import React from 'react';
import { useTable } from 'react-table';

import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px',
    boxShadow: '0px 2px 12px rgba(58, 57, 76, 0.1), 0px 16px 24px -2px rgba(58, 57, 76, 0.12)',
    border: '1px solid #BEBEC5',
    backgroundColor: theme.palette.white.main,
    borderRadius: '8px',
  },
  tableHeader: {
    background: '#F2F2F3',

    // Border radius on table header top corners, applied on the th elements
    '& > tr > th:first-child': {
      borderRadius: '8px 0 0 0',
    },
    '& > tr > th:last-child': {
      borderRadius: '0 8px 0 0',
    },
  },
  tableBody: {
    // Bold the last row
    '& > tr:last-child > td': {
      fontWeight: 'bold',
    },
  },
  tableRow: {

  },
  tableHeaderCell: {
    textAlign: 'left',
    padding: '12px 16px',
    color: theme.palette.grayScale9.main,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  tableDataCell: {
    boxShadow: '0 -1px 0 #BEBEC5', // Use box shadow as small border
    textAlign: 'left',
    padding: '14px 32px 16px 16px',
    color: theme.palette.grayScale9.main,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },

  tooltip: {
    marginTop: '12px',
    backgroundColor: 'unset',
  },
}));

function CampaignCostsTooltip({ campaignCosts, text }) {
  const classes = useStyles();

  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Item',
        accessor: 'item',
      },
      {
        Header: 'Cost',
        accessor: 'cost',
        Cell: ({ value }) => `$${numberWithCommas((value / 100).toFixed(2))}/mo`,
      },
    ],
    [],
  );

  const tableData = React.useMemo(() => {
    // We must support the old pricing scheme here for existing campaigns
    const {
      platformMonthlyCost, platformFeeWaived, mailOutMonthlyCost, extraMailOutsMonthlyCost,

      monthlyRatePerApn, apnsMonthlyCost, extraRecipientsMonthlyCost,

      totalMonthlyCost, // Shared between new/old
    } = campaignCosts;

    if (platformMonthlyCost !== undefined) {
      // OLD
      return [
        {
          item: platformFeeWaived ? 'Platform Fee (Waived)' : 'Platform Fee',
          cost: !platformFeeWaived ? platformMonthlyCost : 0,
        },
        {
          item: 'Lead Mail Outs',
          cost: mailOutMonthlyCost,
        },
        {
          item: 'Extra Recipients',
          cost: extraMailOutsMonthlyCost,
        },
        {
          item: 'Total',
          cost: totalMonthlyCost,
        },
      ];
    }

    // NEW
    return [
      {
        item: 'Cost per APN',
        cost: monthlyRatePerApn,
      },
      {
        item: 'Selected Farm Area',
        cost: apnsMonthlyCost,
      },
      {
        item: 'Extra Recipients',
        cost: extraRecipientsMonthlyCost,
      },
      {
        item: 'Total',
        cost: totalMonthlyCost,
      },
    ];
  }, [campaignCosts]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
    },
  );

  const table = (
    <table {...getTableProps()} className={classes.table}>
      <thead className={classes.tableHeader}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className={classes.tableRow}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className={classes.tableHeaderCell}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className={classes.tableBody}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={classes.tableRow}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} className={classes.tableDataCell}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <Tooltip
      placement="left-start"
      classes={{ tooltip: classes.tooltip }}
      title={table}
      enterTouchDelay={0} // For mobile
      leaveTouchDelay={0} // For mobile
    >
      <div>
        {text}
      </div>
    </Tooltip>
  );
}

export default CampaignCostsTooltip;
